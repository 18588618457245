<template>
  <div class="container">
    <header>
    </header>

    <main class="" style="">
    <div class="row ready" v-show="ready">
      <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
        <div class="card border-0 shadow rounded-3 my-5">
          <div class="card-body p-4 p-sm-5">
            <h5 class="card-title text-center mb-4 fw-light fs-5">Aktiviraj Souvie!</h5>
            <!-- <p class="text-center mb-5">Choose provider of your choice and connect Souvie with photo album. On next scan photo album will be opened.
            </p> -->
            <p class="text-center mb-5">Fotografije s utakmice <b>GNK Dinamo Zagreb - HNK Hajduk Split</b> su spremne! Odaberi cloud po izboru - na njemu će se kreirati fotogalerija i prenijeti fotke. <br> Sljedeći put kada skeniraš, direktno će 
              te voditi na fotogaleriju.
            </p>
            <div>
              <!-- <hr class="my-4"> -->
              <div class="d-grid mb-2">
                <a class="btn btn-google btn-login text-uppercase fw-bold" @click="initializeGoogle">
                  <i class="fab fa-google me-2"></i> Google Photos
                </a>
              </div>
              <div class="d-grid">
                <button class="btn btn-facebook btn-login text-uppercase fw-bold" @click="showToast">
                  <i class="fa-brands fa-dropbox me-2"></i> Dropbox
                </button>
              </div>
              <!-- <div class="d-grid">
                <a class="btn btn-primary btn-login text-uppercase fw-bold" @click="showToast">
                  <i class="fa-solid fa-link me-2"></i> URL
                </a>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row not-ready" v-show="!ready">
      <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
        <div class="card border-0 shadow rounded-3 my-5">
          <div class="card-body p-4 p-sm-5">
            <h5 class="card-title text-center mb-4 fw-light fs-5">Souvie je u pripremi!</h5>
            <!-- <p class="text-center mb-5">Choose provider of your choice and connect Souvie with photo album. On next scan photo album will be opened.
            </p> -->
            <p class="text-center mb-5">Fotografije s utakmice <b>GNK Dinamo Zagreb - HNK Hajduk Split</b> još nisu spremne! Dođi malo kasnije ili unesi e-mail da ti javimo kada su fotke spremne:
            </p>
            <div class="form-group">
              <input class="form-control" type="input" placeholder="Tvoj e-mail">
            </div><br>
            <button type="submit" class="btn btn-primary" style="width: 100%;">Pošalji</button>
          </div>
        </div>
      </div>
    </div>
    </main>

    <footer class="mt-auto text-white-50">
    </footer>

    <div class="toast-container position-absolute top-0 start-50 translate-middle-x p-3">
      <div class="toast align-items-center border-0 text-white bg-secondary" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="d-flex">
          <div class="toast-body">
            This provider is not available at the moment. Please use Google Photos.
          </div>
          <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
/* global bootstrap */
export default {
  name: 'Connect',
  data() {
    return {
      ready: true
    }
  },
  mounted() {
    // Load Bootstrap dynamically when the component is mounted
    this.loadBootstrap();
    // this.initializeGoogle();
  },
  methods: {
    loadBootstrap() {
      // Check if Bootstrap is already loaded
      if (typeof bootstrap === 'undefined') {
        // If not, load it dynamically
        const script = document.createElement('script');
        script.src = '/bootstrap/js/bootstrap.min.js';

        script.onload = () => {
          // Bootstrap is now loaded, you can use it here
          console.log("Bootstrap loaded")
          this.initializeBootstrap();
        };

        // Append the script to the document
        document.head.appendChild(script);
      } else {
        // If Bootstrap is already loaded, initialize it
        this.initializeBootstrap();
      }
    },
    initializeBootstrap() {
      var toastElList = [].slice.call(document.querySelectorAll('.toast'))
      this.toastElList = toastElList.map(function (toastEl) {
        return new bootstrap.Toast(toastEl)
      })
    },
    showToast() {
      console.log(this.toastElList);
      this.toastElList[0].show();
    },
    // initializeGoogle() {
    //   window.google.accounts.id.initialize({
    //     client_id: '129129713157-17iunisl22i5rqpgmpsn2ttj4v4c881p.apps.googleusercontent.com',
    //     callback: this.handleCredentialResponse
    //   });
    //   window.google.accounts.id.prompt();
    // },
    // handleCredentialResponse(response) {
    //   console.log("response");
    //   console.log(response);
    //   // this.createGooglePhotosAlbum(response.credential);
    // },
    initializeGoogle() {
      console.log("initializeGoogle");
      const client = window.google.accounts.oauth2.initTokenClient({
        client_id: '129129713157-17iunisl22i5rqpgmpsn2ttj4v4c881p.apps.googleusercontent.com',
        scope: 'https://www.googleapis.com/auth/photoslibrary.appendonly',
        callback: (response) => {
          console.log(response);
          this.createGooglePhotosAlbum(response.access_token);
        },
      });

      client.requestAccessToken();
    },
    async createGooglePhotosAlbum(accessToken) {
      console.log(accessToken)
      // Make an API request to create a Google Photos album
      const response = await fetch('https://photoslibrary.googleapis.com/v1/albums', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          album: {
            title: 'My Souvie Photo Album',
          },
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to create Google Photos album: ${errorData.error.message}`);
      }

      const albumData = await response.json();
      return albumData;
    },

    // async signInWithGoogle() {
    //   try {
    //     console.log(window.gapi);
    //     // Load the Google API client library
    //     await this.loadGoogleAPI();

    //     // Sign in the user with Google
    //     const authResult = await window.gapi.auth2.getAuthInstance().signIn();

    //     // Check if the user is signed in
    //     if (authResult && authResult.isSignedIn()) {
    //       // Get the user's Google Photos access token
    //       const accessToken = authResult.getAuthResponse().access_token;

    //       // Create a Google Photos album
    //       await this.createGooglePhotosAlbum(accessToken);

    //       // You can update the UI or perform other actions here
    //       console.log('Google Photos album created successfully!');
    //     } else {
    //       console.error('Failed to sign in with Google.');
    //     }
    //   } catch (error) {
    //     console.error('Error during Google Sign-In:', error);
    //   }
    // },

    // async loadGoogleAPI() {
    //   // Load the Google API client library
    //   await new Promise((resolve, reject) => {
    //     window.gapi.load('client:auth2', {
    //       callback: resolve,
    //       onerror: reject,
    //     });
    //   });

    //   // Initialize the Google API client with your API key and Photos API client ID
    //   await window.gapi.client.init({
    //     apiKey: 'AIzaSyBX6JywRDPGmlTZuQLuoNs_PGwkyKWT-sw',
    //     clientId: '129129713157-17iunisl22i5rqpgmpsn2ttj4v4c881p.apps.googleusercontent.com',
    //     scope: 'https://www.googleapis.com/auth/photoslibrary',
    //   });

    //   // Check if the user is already signed in
    //   await window.gapi.auth2.getAuthInstance().isSignedIn.get();
    // },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn-login {
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  padding: 0.75rem 1rem;
}

.btn-google {
  color: white !important;
  background-color: #ea4335;
}

.btn-facebook {
  color: white !important;
  background-color: #3b5998;
}
</style>
